import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
// 引入路由
import router from '@/router'
// 创建一个axios实例
const service = axios.create({
  // 由于这里时我自己做了跨域代理，所以去掉了
  baseURL: 'https://ganfangzhi.com/',
  headers: {
    'app-name': "pc",
    'Content-Type': 'application/json;charset=UTF-8'
  },
  timeout: 150000
})
service.interceptors.request.use(
  config => {
    const accessTokenKey = store.state.accessToken
    // const userType = store.state.userDetail.user_type
    if (accessTokenKey) {
      config.headers.token = accessTokenKey
      // config.headers['user-type']=userType
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
let timeShow = true
// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data

    // 如果自定义代码不是200，则判断为错误
    if (res.code !== 200) {
      if (res.code == -1) {
        // 直接重置状态
        store.dispatch('increment')
      }
      if(res.code==404){
        router.push({ path: '/notFont' })
        return
      }
      if (timeShow) {
        Message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 3 * 1000
        })
      }
      timeShow = false
      setTimeout(() => {
        timeShow=true
      }, 5000)
      if (res.msg == '权限不足') {
        // 进入企业端权限不足直接返回首页
        router.push({ path: '/' })
        return
      }
      if (res.msg == '网站升级中请联系管理员！') {
        // 进入企业端权限不足直接返回首页
        router.push({ path: '/website' })
        return
      }
     


      return res
    } else {
      return res
    }
  },
  error => {
    console.log(error)
    // 非401未认证，直接放行
    // if (error.response && error.response.status !== 401) {
    //   // Message({ message: error.msg, type: 'error', duration: 5 * 1000 })
      
    // }
    // 直接重置状态
    // store.dispatch('increment')
    return Promise.reject(error)
  }
)

export default service
