import request from '@/util/request'

export default {
    // 获取配置列表
    getUserConfig() {
        return request({
            url: "/api/common/getUserConfig",
            method: "get"
        })
    },
    //获取企业地址
    getAddress(){
        return request({
            url:"/api/region",
            method:"get"
        })
    },
    // 上传图片
    uploadImg(data = {}){
        return request({
            url:"/api/upload/image",
            method:"post",
            data
        })
    },
    // 职位类别
    positionCategory(){
        return request({
            url:"/api/position.category/lists",
            method:"get"
        })
    },
    // 获取广告图
    advertObj(){
        return request({
            url:"/api/operate.advert/lists",
            method:"get"
        })
    },
    // 上传附件简历
    uploadResume(data={}){
        return request({
            url:"/api/user/uploadResume",
            method:"post",
            data
        })
    },
    // 获取附件简历
    getAnnexResume(query){
        return request({
            url:"/api/user/getAnnexResume",
            method:"post",
            data:{...query}
        })
    },
     // 删除附件简历
     delAnnexResume(){
        return request({
            url:"/api/user/delAnnexResume",
            method:"post"
        })
    },
    // 意见反馈
    feedback(query){
        return request({
            url:"/api/user/feedback",
            method:"post",
            data:{...query}
        })
    },
    // 获取系统消息
    getNotice(query){
        return request({
            url:"/api/user/getNotice",
            method:"post",
            data:{...query}
        })
    },
    // 活动列表
    operateList(){
        return request({
            url:"/api/operate.activity/lists",
            method:"get"
        })
    },
    // 获取图形验证码
    getCaptcha(path=''){
        return request({
            url:`/api/common/getCaptcha?path=${path}`,
            method:'get'
        })
    },
    // 获取后台设置的企业福利
    getWelfare(){
        return request({
            url:"/api/enterprise/getWelfare",
            method:"post"
        })
    },
    // 获取网站设置
    getSite(){
        return request({
            url:"/api/common/getSite",
            method:"get"
        })
    },
    // 模糊查询
    searchListByName(query){
        return request({
            url:"/api/enterprise/searchListByName",
            method:"post",
            data:{...query}
        })
    },
     // 获取违禁词
     checkWords(query){
        return request({
            url:"/api/common/checkWords",
            method:"post",
            data:{...query}
        })
    },
    // 获取合同
    getContract(order_no){
        return request({
            url:`/api/common/readWords?order_no=${order_no}`,
            method:"get"
        })
    }
}