import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {
  SDKAPPID
} from './debug/GenerateTestUserSig'
// 引入tim
import TIM from "tim-js-sdk"
import COS from "cos-js-sdk-v5"
import TIMUploadPlugin from 'tim-upload-plugin'
let options = {
  SDKAppID: SDKAPPID //将0替换为及时通信IM应用的SDKAppID
}
let tim = TIM.create(options)
tim.setLogLevel(0)//设置SDK日志输出级别
tim.registerPlugin({ 'cos-js-sdk': COS, 'tim-upload-plugin': TIMUploadPlugin })//注册COS SDK插件
Vue.prototype.$tim = tim
// 工具方法
import * as util from "./util/util.js"
// 挂载到Vue原型上
Vue.prototype.$util = util
// 引入rem.js文件
import "./util/rem"
// 引入公共样式
import "./css/comm.css"
// 引入动画样式
import "./css/animate.css"
// 挂载到vue实例上
Vue.prototype.$store = store

router.afterEach((to,from,next)=>{
  setTimeout(()=>{

    var _hmt = _hmt || [];
 
    (function() {
 
     //每次执行前，先移除上次插入的代码
 
     document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
 
     var hm = document.createElement("script");
 
     hm.src = "https://hm.baidu.com/hm.js?"+'9a2f9e04fb5e3b9577daaaf0ec86c314';
 
     hm.id = "baidu_tj"
 
     var s = document.getElementsByTagName("script")[0];
 
     s.parentNode.insertBefore(hm, s);
 
    })();
 
  },0);
})

// 创建公共方法
Vue.prototype.$EventBus = new Vue()
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
