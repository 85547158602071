<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import api from "./api/system"
import toolApi from "./api/tool"
export default {
  created() {
    this.initLogin()
    this.expireToken()
    this.settings()
    this.getDetailKf()
  },
  methods: {
    // 过期时间
    expireToken() {
      this.$store.commit("expireToken");
    },
    // 初始化登录状态
    initLogin() {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const accessToken = localStorage.getItem("accessToken");
      const sig = JSON.parse(localStorage.getItem("sig"));
      const userDetail = JSON.parse(localStorage.getItem("userDetail"));
      if (userInfo && accessToken) {
        this.$store.commit("setState", {
          userInfo,
          accessToken,
        });
      }
      if(sig){
        this.$store.commit('SET_SIG',sig)
      }
      if(userDetail){
        this.$store.commit('SET_USER',userDetail)
      }
    },
    // 获取系统相关的接口
    settings(){
      api.settings().then(res=>{
        if(res.code==200){
          if(res.data.is_open!=1){
            this.$router.push({path:"/website"})
          }
          this.$store.commit('get_setting',res.data)
        }
      })
    },
    // 获取客服微信
    getDetailKf(){
      api.getDefaultKefu().then(res=>{
        if(res.code==200){
          localStorage.setItem('kfObj',JSON.stringify(res.data))
        }
      })
    }
  },
};
</script>
<style lang="less">
div, span {
  word-break:break-all;
}
</style>
